// RestrictedUserPrivateRoute.js
import React from "react";
import { Navigate } from "react-router-dom";
import { useFirebaseAuth } from "./FirebaseAuthContext";

const RestrictedUserPrivateRoute = ({ children }) => {
  const { currentUser } = useFirebaseAuth();

  // Lista de IDs permitidos para essa rota
  const allowedUserIds = [
    "Fo1yuMUd8FSa7mtjFYFiabihXxo1", // Felipe
    "YvgRjnUgawc2hdnl9LrSjRgOVKf1", // Clara
    "kiTDkAkbUhZ3WjFfIfyWujyRzdX2", // Ana
    "uGRGMbH66lTGixjuHzy3lvQtNx52", // Carol
  ];

  // Verifica se o usuário atual está autenticado e tem um UID permitido
  if (!currentUser || !allowedUserIds.includes(currentUser.uid)) {
    return <Navigate to="/loginleads" />; // Redireciona para login se não autorizado
  }

  return children;
};

export default RestrictedUserPrivateRoute;
