// FirebaseAuthContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../config/firebase.js";
import {
  onAuthStateChanged,
  signOut,
  setPersistence,
  browserSessionPersistence,
} from "firebase/auth";

// Cria o contexto
const FirebaseAuthContext = createContext();

// Cria um hook para usar o contexto facilmente
export const useFirebaseAuth = () => useContext(FirebaseAuthContext);

// Componente provedor do contexto
export const FirebaseAuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    // Adiciona o evento para logout ao fechar a aba
    const handleBeforeUnload = () => {
      logout();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      unsubscribe();
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // Função de logout
  const logout = () => {
    signOut(auth)
      .then(() => {
        setCurrentUser(null); // Limpa o usuário atual após o logout
      })
      .catch((error) => {
        console.error("Erro ao fazer logout:", error);
      });
  };

  const value = {
    currentUser,
    logout,
  };

  return (
    <FirebaseAuthContext.Provider value={value}>
      {!loading && children}
    </FirebaseAuthContext.Provider>
  );
};
