// Login.js
import React, { useState } from "react";
import { useAuth } from "./AuthContext";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useAuth();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!login(username, password)) {
      alert("Credenciais inválidas");
    }
  };

  return (
    <div className="login">
      <div className="login-left">
        <h2>Login</h2>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label>
              Usuário:
              <input
                type="text"
                value={username}
                placeholder="Nome Usuário"
                onChange={(e) => setUsername(e.target.value)}
              />
            </label>
          </div>
          <div className="input-group">
            <label>
              Senha:
              <input
                type="password"
                value={password}
                placeholder="Senha"
                onChange={(e) => setPassword(e.target.value)}
              />
            </label>
          </div>
          <button type="submit">Entrar</button>
        </form>
      </div>
      <div className="login-right">
        <img src="/images/login.webp" alt="login-ana" />
      </div>
    </div>
  );
};

export default Login;
