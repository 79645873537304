import React, { useState, useEffect } from "react";
//import './Depilacao.css';
import "./main.css"; // Estilo para o layout

function Depilacao() {
  const [selectedGender, setSelectedGender] = useState("mulher"); // Default gender is "woman"
  const [activeArea, setActiveArea] = useState(null);
  const [svgContent, setSvgContent] = useState(null); // State to hold the fetched SVG content

  // Fetch and set the SVG content dynamically based on the selected gender
  useEffect(() => {
    const fetchSvg = async () => {
      const svgFilePath =
        selectedGender === "homem" ? "/svg/male.svg" : "/svg/female.svg";
      try {
        const response = await fetch(svgFilePath);
        const svgText = await response.text();
        setSvgContent(svgText); // Save the SVG content to state
      } catch (error) {
        console.error("Error fetching SVG:", error);
      }
    };

    fetchSvg();
  }, [selectedGender]);

  // Mapping areas for both genders
  const maleAreas = {
    G: [
      { name: "Barba completa", id: "barba-completa" },
      { name: "Braços", id: "braco" },
      { name: "Coxas", id: "coxa" },
      { name: "1/2 perna", id: "meia-perna" },
      { name: "Posterior completo", id: "posterior-completo" },
    ],
    M: [
      { name: "Tórax", id: "torax" },
      { name: "Abdômen", id: "abdomen" },
      { name: "Antebraço", id: "antebraço " },
      { name: "Virilha completa", id: "virilha-completa" },
      { name: "Base pênis", id: "base-penis" },
      { name: "Ombro", id: "ombro" },
      { name: "Orelha", id: "orelha" },
      { name: "Dorsal", id: "dorsal" },
      { name: "Lombar", id: "lombar" },
      { name: "Glúteos", id: "gluteo" },
      { name: "Interglúteos", id: "intergluteo" },
      { name: "Perianal", id: "perianal" },
    ],
    P: [
      { name: "Glabela", id: "glabela" },
      { name: "Nariz", id: "nariz" },
      { name: "Bigode", id: "bigode" },
      { name: "Testa", id: "testa" },
      { name: "Axila", id: "axila" },
      { name: "Costeleta", id: "costeleta" },
      { name: "Maxilar", id: "maxilar" },
      { name: "Cavanhaque ", id: "cavanhaque" },
      { name: "Faixa de barba (pescoço)", id: "faixa-de-barba" },
      { name: "Mãos e Dedos", id: "maos-dedos" },
      { name: "Pés e Dedos", id: "pes-dedos" },
      { name: "Nuca", id: "nuca" },
    ],
  };

  const femaleAreas = {
    G: [
      { name: "Coxas", id: "coxa" },
      { name: "1/2 perna", id: "meia-perna" },
      // Add more areas as necessary
    ],
    M: [
      { name: "Rosto", id: "rosto" },
      { name: "Braço", id: "braço" },
      { name: "Abdômen", id: "abdomen" },
      { name: "Antebraço", id: "antebraço" },
      { name: "Virilha Completa", id: "virilha-completa" },
      { name: "Glúteos", id: "gluteo" },
    ],
    P: [
      { name: "Testa", id: "testa" },
      { name: "Face Lateral", id: "face-lateral" },
      { name: "Queixo", id: "queixo" },
      { name: "Axila", id: "axila" },
      { name: "Aréola", id: "areola" },
      { name: "Faixa de Coxa (interno)", id: "faixa-de-coxa" },
      { name: "Joelho", id: "joelho" },
      { name: "Glabela", id: "glabela" },
      { name: "Nariz", id: "nariz" },
      { name: "Buço", id: "buço" },
      { name: "Pescoço", id: "pescoço" },
      { name: "Mama", id: "mama" },
      { name: "Entre Seios", id: "entre-seios" },
      { name: "Linha Alba", id: "linha-alba" },
      { name: "Virilha Simples (biquini)", id: "virilha-biquini" },
      { name: "Virilha Íntima", id: "virilha-intima" },
      { name: "Pés e Dedos", id: "pes-dedos" },
      { name: "Orelhas", id: "orelha" },
      { name: "Nuca", id: "nuca" },
      { name: "Lombar", id: "lombar" },
      { name: "Interglúteos", id: "intergluteo" },
      { name: "Perianal", id: "perianal" },
      { name: "Mãos e Dedos", id: "maos-dedos" },
    ],
  };

  const handleClick = (area) => {
    setActiveArea(activeArea === area ? null : area); // Toggle between opening and closing the list
  };

  const areas = selectedGender === "homem" ? maleAreas : femaleAreas;

  // Function to handle mouseover to highlight SVG area
  const handleMouseEnter = (id) => {
    const element = document.getElementById(id);
    if (element) {
      // Defina o preenchimento e o brilho
      element.setAttribute("fill", "none");
      element.setAttribute("fill-opacity", "1"); // Opacidade máxima
      element.setAttribute("stroke", "red"); // Cor do contorno
      element.setAttribute("stroke-width", "15"); // Contorno mais grosso
      element.style.transition = "transform 0.3s ease, filter 0.3s ease"; // Transição suave
      element.style.filter = "drop-shadow(0 0 10px black)"; // Adiciona um brilho ao redor do elemento
    }
  };

  const handleMouseLeave = (id) => {
    const element = document.getElementById(id);
    if (element) {
      // Reverte as propriedades ao remover o mouse
      element.setAttribute("fill", "none");
      element.setAttribute("stroke", "#26b6b4"); // Cor do contorno
      element.setAttribute("stroke-width", "1"); // Volta ao tamanho original do contorno
      element.style.filter = "none"; // Remove o brilho
    }
  };

  return (
    <section id="depilacao">
      <div className="content-container">
        <h2>Nossos Tratamentos</h2>
        <p>
          Ao contrário de métodos tradicionais, como a lâmina e a cera, que
          removem os pelos de forma temporária, a depilação a laser tem como
          objetivo eliminar o pelo pela raiz, impedindo seu crescimento futuro.
          O procedimento utiliza a luz do laser, que é absorvida pela melanina
          do pelo, aquecendo o folículo piloso para cauterizá-lo, sem danificar
          a pele ao redor. Esse método oferece uma solução eficaz e duradoura
          para a remoção dos pelos, proporcionando uma pele lisa por mais tempo.
          Nosso equipamento de laser, que utiliza tecnologia exclusiva da marca,
          é de diodo com fibra óptica, atuando em três comprimentos de onda: 755
          nm ± 5 nm (Alexandrite), 810 nm ± 5 nm (Diodo) e 1064 nm ± 5 nm
          (ND-YAG). Dessa forma, o laser penetra mais profundamente na pele,
          sendo mais eficaz na eliminação dos pelos. A máquina também conta com
          uma ponteira resfriada, que atinge -5°C, proporcionando maior conforto
          ao cliente durante a aplicação. Essa tecnologia nos permite tratar uma
          variedade maior de tipos de pele e pelos, garantindo resultados mais
          eficientes e seguros.
        </p>

        {/* Gender selection buttons */}
        <div className="gender-buttons">
          <button
            className={selectedGender === "homem" ? "active" : ""}
            onClick={() => setSelectedGender("homem")}
          >
            Homem
          </button>
          <button
            className={selectedGender === "mulher" ? "active" : ""}
            onClick={() => setSelectedGender("mulher")}
          >
            Mulher
          </button>
        </div>

        <div className="treatment-layout">
          {/* Left column: Area menu */}
          <div className="area-menu">
            <button onClick={() => handleClick("G")}>Área G</button>
            <button onClick={() => handleClick("M")}>Área M</button>
            <button onClick={() => handleClick("P")}>Área P</button>
          </div>

          {/* Middle column: List of regions */}
          <div className={`area-list ${activeArea ? "visible" : "hidden"}`}>
            {activeArea && (
              <ul>
                {areas[activeArea].map((item, index) => (
                  <li
                    key={index}
                    onMouseEnter={() => handleMouseEnter(item.id)}
                    onMouseLeave={() => handleMouseLeave(item.id)}
                  >
                    {item.name}
                  </li>
                ))}
              </ul>
            )}
          </div>

          {/* Right column: SVG icon */}
          <div className="treatment-icon">
            {/* Render the SVG directly from the fetched content */}
            <div
              dangerouslySetInnerHTML={{ __html: svgContent }}
              className="treatment-svg"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Depilacao;
