import React from "react";
import { Navigate } from "react-router-dom";
import { useFirebaseAuth } from "./FirebaseAuthContext";

const UserPrivateRoute = ({ children }) => {
  const { currentUser } = useFirebaseAuth();

  if (!currentUser) {
    return <Navigate to="/loginfirebase" />; // Redireciona para LoginFirebase
  }

  return children;
};

export default UserPrivateRoute;
