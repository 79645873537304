import React, { useState } from "react";
//import './DermatoEsteticos.css';
import "./main.css"; // Estilo para o layout

function DermatoEsteticos() {
  const [activeId, setActiveId] = useState(null);

  const procedimentos = [
    {
      id: 1,
      src: "/images/omer/clareamento-intimo.jpg",
      alt: "Procedimento Clareamento Intimo",
      descricao:
        "Você sente vergonha por conta do escurecimento da região íntima e virilha? Ele pode ocorrer por diversos motivos, como o atrito com a roupa, depilação com cera ou lâmina e até alterações hormonais 😯 <br/><br/>Se isso é algo que te incomoda, escolha a liberdade que a Maislaser pode te proporcionar com a tecnologia ND Yag!  🤍",
    },
    {
      id: 2,
      src: "/images/omer/tattoo.jpg",
      alt: "Procedimento Tattoo",
      descricao:
        "Diga adeus aos desenhos que já não fazem mais sentido na sua vida e nem na sua pele! Para fazer a remoção de tatuagem, os lasers “quebram” as cores do pigmento com um feixe de luz de alta intensidade. <br/><br/>Na prática, o pigmento de tatuagem preta absorve todos os comprimentos de onda do laser, o que torna a cor mais fácil de tratar. Com a tecnologia ND Yag, além da cor preta, o laser também remove tatuagens coloridas e cover-up (cobertura) com mais qualidade.",
    },
    {
      id: 3,
      src: "/images/omer/micropigmentação.jpg",
      alt: "Procedimento Remoção de Micropigmentação",
      descricao:
        "O desenho da sua sobrancelha não combina mais com você? Com a nova tecnologia Maislaser é possível remover sua micropigmentação com mais segurança! Muitas pessoas escolhem o método de remoção de micropigmentação de sobrancelhas por não estarem satisfeitos. <br/><br/>   Infelizmente, em alguns casos, o procedimento pode ser mal executado, o que pode levar a resultados indesejados. O suor do rosto ou a exposição ao sol, por exemplo, são fatores que podem influenciar na oxidação do pigmento, causando a mudança na tonalidade original. Com a nova tecnologia ND Yag, a remoção pode ser feita de forma segura e eficaz. Não perca tempo e vem para a mais amada do Brasil!  🤍",
    },
    {
      id: 4,
      src: "/images/omer/rejuvenescimento.jpg",
      alt: "Procedimento Rejuvenescimento Facial",
      descricao:
        "Com o passar do tempo, nosso corpo deixa de produzir colágeno e isso fica visível na nossa pele 👀<br/><br/>O novo equipamento da Maislaser, com a combinação correta de duração de pulso e fluência, são eficientes para estimular a produção de colágeno e consequentemente resultar no rejuvenescimento da pele. 😍<br/><br/> Dessa forma, é possível garantir uma pele mais jovem e tratar as manchas em pele madura. Conheça o laser ND Yag! 🤍",
    },
    {
      id: 5,
      src: "/images/omer/peeling.jpg",
      alt: "Procedimento Peeling",
      descricao:
        "A Maislaser está revolucionando o Peeling de Hollywood! 🤩 <br/><br/>Indicado para rejuvenescimento, esfoliação, controle da oleosidade, suavização de texturas ásperas e outras irregularidades, como manchas e acne, realçando a cor e luminosidade da pele. Nosso novo protocolo de Peeling de Carbono pode ser aplicado na face, colo, pescoço, mãos, glúteos e virilha, acredita? 😱 <br/><br/>Ele traz resultados surpreendentes sem causar aquele efeito de descamação na pele. Vem conhecer as vantagens do novo laser ND Yag para ter a pele de milhões  🤍",
    },
    {
      id: 6,
      src: "/images/omer/onicomicose.jpg",
      alt: "Procedimento Onicomicose",
      descricao:
        "O laser ND Yag quando utilizado com baixas energias e pulsos curtos estimula a produção de fibras colágenas, que aplicado nas unhas, melhora a microcirculação das extremidades, acelera o crescimento e inibe a multiplicação dos microrganismos. Dessa forma, é possível o tratamento para fungos e micoses nas unhas das mãos e dos pés. <br/><br/>A Maislaser oferece mais saúde para sua pele e para as unhas também  🤍",
    },
    {
      id: 7,
      src: "/images/omer/melasma.jpg",
      alt: "Procedimento Melasma",
      descricao:
        "Você conhece o melasma? 🤔 <br/><br/>Ele é caracterizado por manchas escuras na pele que surgem geralmente no rosto, mas também podem aparecer nos braços, pescoço e colo. <br/><br/>Nosso novo protocolo utiliza o laser ND Yag para suavizar a diferença de pigmentação, e o melhor: sem provocar aquela sensação desagradável de queimadura, que é comum em peeling químico. 🤩",
    },
    {
      id: 8,
      src: "/images/omer/olheiras.jpg",
      alt: "Procedimento Olheiras",
      descricao:
        "Com a técnica certa, o laser ND Yag é capaz de tratar até as olheiras mais pigmentadas 👀 <br/><br/>Graças aos pulsos emitidos em rajadas ultra rápidas, o laser ND Yag direciona a energia para a área afetada, mantendo a região ao redor preservada e saudável.  🤍",
    },
    {
      id: 9,
      src: "/images/omer/foliculite.jpg",
      alt: "Procedimento Foliculite",
      descricao:
        "A foliculite é a infecção ou inflamação que atinge o folículo piloso, estrutura onde nascem os pelos. A condição, em casos superficiais, apresenta pequenas pústulas, semelhantes a espinhas, que deixam a região avermelhada, sensível e dolorida. Com o novo protocolo da Maislaser, dizer adeus à foliculite nunca foi tão prático!  🤍 <br/><br/> Preparamos um novo tratamento para você se despedir de vez dessas inflamações tão incômodas causadas pelas lâminas. Associar o tratamento com a depilação a laser torna a evolução do processo ainda mais positiva. Vem para a Maislaser 🤩",
    },
    {
      id: 10,
      src: "/images/omer/mancha-senil.jpg",
      alt: "Procedimento Mancha Senil",
      descricao:
        "Com o passar do tempo, nosso corpo deixa de produzir colágeno e isso fica visível na nossa pele 👀 <br/><br/>O novo equipamento da Maislaser, com a combinação correta de duração de pulso e fluência, são eficientes para estimular a produção de colágeno e consequentemente resultar no rejuvenescimento da pele. 😍 <br/><br/>Dessa forma, é possível garantir uma pele mais jovem e tratar as manchas em pele madura. Conheça o laser ND Yag!  🤍",
    },
    {
      id: 11,
      src: "/images/omer/acne.webp",
      alt: "Procedimento Acne",
      descricao:
        "Oleosidade excessiva da pele, alterações hormonais comuns durante a puberdade e a colonização da pele por bactérias também colabora com o processo. Mas com a Maislaser isso não precisa ser mais um desafio! <br/><br/>Conheça nossos novos protocolos e garanta uma pele mais saudável e sem lesões com o laser ND Yag, e o melhor: para acne ativa em pele juvenil e adulta 😎",
    },
    {
      id: 12,
      src: "/images/omer/bbglow.jpg",
      alt: "Procedimento BBGlow",
      descricao:
        "Nosso protocolo BBGlow propicia melhora da nutrição da pele garantindo o efeito de pele tratada, com viço, diminuição dos poros e dando aquele efeito “glow up” que amamos. 🤩 <br/><br/> É possível ter uma pele de porcelana, como das atrizes coreanas, com o laser ND Yag! Marque já sua avaliação!  🤍",
    },
    {
      id: 13,
      src: "/images/omer/mancha-de-queimadura.jpg",
      alt: "Procedimento Mancha de Queimadura",
      descricao:
        "As marcas geradas por uma queimadura causam uma inflamação que estimula na pele a produção de melanina na área lesionada o que favorece o aparecimento de áreas escuras (hiperpigmentação pós-inflamatória) ou manchas. <br/><br/>Nossa nova tecnologia utiliza o laser ND Yag para suavizar essas áreas de hiperpigmentação pós-inflamatória e manchas de queimaduras pós-laser, devolvendo sua confiança na sua pele 😍",
    },
    {
      id: 14,
      src: "/images/omer/estrias.jpg",
      alt: "Procedimento Estrias",
      descricao:
        "Estrias são marcas que mostram que nosso corpo passou por mudanças e podem afetar bastante a nossa autoestima. Pensando nisso, a Maislaser preparou um protocolo exclusivo para tratar desde as estrias mais recentes (vermelhas) até aquelas que já estão brancas, e o melhor: nosso tratamento é seguro para todos os tons de pele! 🤩 <br/><br/>Agende sua avaliação e saiba como podemos te ajudar a recuperar sua autoestima  🤍",
    },
    {
      id: 15,
      src: "/images/omer/pele-oleosa.jpg",
      alt: "Procedimento Pele Oleosa e Poros",
      descricao:
        "Quando as glândulas sebáceas produzem mais sebo que o necessário, a pele torna-se gordurosa e brilhante, com poros dilatados principalmente nas regiões central da face, nariz, bochechas e queixo. 😷 <br/><br/>Um dos novos tratamentos que a Maislaser oferece apresenta como objetivo reduzir a produção de sebo, fechamento de poros, diminuir a oleosidade, bem como, provocar o aumento da produção de colágeno, trazendo um efeito de pele de porcelana. 🤩",
    },
    {
      id: 16,
      src: "/images/omer/cicatrizes.jpg",
      alt: "Procedimento Cicatrizes",
      descricao:
        "Embora sejam respostas naturais do nosso corpo, as cicatrizes podem causar um grande incômodo e prejudicar nossa autoestima e autoconfiança. <br/><br/>Nosso novo protocolo utiliza a tecnologia ND Yag, um laser de alta performance para diminuir a pigmentação das cicatrizes, trazendo um visual mais agradável e uniforme para a pele. 🤩",
    },
    {
      id: 17,
      src: "/images/omer/rosacea.jpg",
      alt: "Procedimento Rosacea",
      descricao:
        "A rosácea é uma doença inflamatória crônica da pele, que causa vermelhidão no centro do rosto, coceira e sensação de ardor.<br/><br/>Ainda não existe uma cura definitiva para essa condição, mas os sintomas podem ser minimizados com nosso tratamento e protocolo exclusivo que utiliza o laser ND Yag 🤍",
    },
    {
      id: 18,
      src: "/images/omer/sardas.jpg",
      alt: "Procedimento Sardas",
      descricao:
        "As sardas costumam aparecer em locais mais atingidos por queimaduras solares, como face, colo e ombros 🌞<br/><br/>Essas manchinhas, chamadas de efélides, não tem uma cura, mas a Maislaser sabe que podem incomodar, então desenvolvemos um protocolo que permite ter um controle sobre elas e amenizá-las. <br/><br/>Nosso objetivo é devolver sua autoestima, com a melhor tecnologia do mercado 🤍",
    },
    {
      id: 19,
      src: "/images/omer/vasinhos-faciais.jpg",
      alt: "Procedimento Vasinhos Faciais",
      descricao:
        "Sabe aquelas veias bem fininhas que ficam visíveis através da pele? Elas são chamadas de telangiectasia e podem causar uma sensação de queimação onde surgem. <br/><br/>A Maislaser trouxe o laser ND Yag pensando na saúde da sua pele, já que ele é indicado para o tratamento da telangiectasia facial realizando a eliminação e suavização das veias visivelmente expostas, além de outras condições de pele 😍",
    },
  ];

  const toggleItem = (id) => {
    setActiveId(activeId === id ? null : id); // Alterna entre imagem e descrição
  };

  return (
    <section id="dermato-esteticos">
      <div className="content-container">
        <div className="procedimentos-intro">
          <h2>Procedimentos Dermato Estéticos</h2>
          <p>
            Na maislaser, nossos tratamentos dermatoestéticos utilizam
            tecnologia de laser com pulsos curtos de alta intensidade,
            fragmentando os pigmentos da pele em partículas menores, que são
            eliminadas pelo sistema linfático. Com dois comprimentos de onda
            (1064nm e 532nm), o equipamento se adapta à cor do pigmento,
            garantindo uma ação eficaz com menor risco de afetar os vasos
            adjacentes. Nossos protocolos exclusivos são projetados para
            oferecer resultados incríveis na saúde e na estética da sua pele.
            Nossa equipe de especialistas está pronta para orientá-lo(a) em cada
            etapa, assegurando os melhores cuidados. Conheça nossos serviços e
            descubra como transformar sua pele com segurança e eficácia. <br />
            Agende sua consulta e dê o primeiro passo para uma pele mais
            saudável e radiante!
          </p>
        </div>
        <div className="procedimentos-grid">
          {procedimentos.map((procedimento) => (
            <div
              key={procedimento.id}
              className={`procedimento-item ${
                activeId === procedimento.id ? "active" : ""
              }`}
              onClick={() => toggleItem(procedimento.id)}
            >
              <img src={procedimento.src} alt={procedimento.alt} />
              <div className="procedimento-descricao">
                <p
                  dangerouslySetInnerHTML={{ __html: procedimento.descricao }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default DermatoEsteticos;
