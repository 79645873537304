// LoginFirebase.js
import React, { useState } from "react";
import { auth } from "../config/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const LoginFirebase = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/controledegestao");
    } catch (err) {
      setError("Erro ao fazer login. Verifique suas credenciais.");
      console.error("Login error:", err);
    }
  };

  return (
    <div className="login">
      <div className="login-left">
        <h2>Login</h2>
        <form onSubmit={handleLogin}>
          <div className="input-group">
            <label>
              Usuário:
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
              />
            </label>
          </div>
          <div className="input-group">
            <label>
              Senha:
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Senha"
                required
              />
            </label>
          </div>

          <button type="submit">Entrar</button>
        </form>
      </div>
      <div className="login-right">
        <img src="/images/login.webp" alt="login-ana" />
      </div>

      {error && <p>{error}</p>}
    </div>
  );
};

export default LoginFirebase;
