import React, { useState } from "react";
import { Link } from "react-router-dom";

//import './Header.css';
import "./main.css"; // Estilo para o layout

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <section id="header">
      <header>
        <div className="logo">
          <img src="/images/logo.png" alt="maislaser logo" />
        </div>
        <nav className={menuOpen ? "open" : ""}>
          <ul>
            <li>
              <Link to="/">Início</Link>
            </li>
            <li>
              <Link to="/depilacao">Depilação a Laser</Link>
            </li>
            <li>
              <Link to="/dermato-esteticos">Dermato Estéticos</Link>
            </li>
            <li>
              <Link to="/ganhe-sessoes">Ganhe 3 Sessões Gratuitas</Link>
            </li>
            <li>
              <Link to="/pelometro">Pelômetro</Link>
            </li>
          </ul>
        </nav>
        <div className="hamburger" onClick={toggleMenu}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
      </header>
    </section>
  );
}

export default Header;
