import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { FirebaseAuthProvider } from "./components/FirebaseAuthContext";
import Header from "./components/Header";
import Home from "./components/Home";
import Contato from "./components/Contato";
import Footer from "./components/Footer";
import SubFooter from "./components/SubFooter";
import Perguntas from "./components/Perguntas";
import DermatoEsteticos from "./components/DermatoEsteticos";
import GanheSessoes from "./components/GanheSessoes";
import Depilacao from "./components/Depilacao";
import Pelometro from "./components/Pelometro";
import { AuthProvider } from "./components/AuthContext";
import Login from "./components/Login";
import PrivateRoute from "./components/PrivateRoute";
import CalculadoraOrcamentoConsultoras from "./components/CalculadoraOrcamentoConsultoras";
import CalculadoraOrcamentoTecnicas from "./components/CalculadoraOrcamentoTecnicas";
import AnamneseFormDepilacao from "./components/AnamneseFormDepilacao";
import AnamneseFormOmer from "./components/AnamneseFormOmer";
import AdminPrivateRoute from "./components/AdminPrivateRoute";
import ControleGestao from "./components/ControleGestao";
import LoginFirebase from "./components/LoginFirebase";
import UserDashboard from "./components/UserDashboard";
import UserPrivateRoute from "./components/UserPrivateRoute";
import GestaoLeads from "./components/GestaoLeads";
import RestrictedUserPrivateRoute from "./components/RestrictedUserPrivateRoute";
import LoginLeads from "./components/LoginLeads";

function App() {
  return (
    <FirebaseAuthProvider>
      {" "}
      {/* Wrap entire app with FirebaseAuthProvider */}
      <Router>
        <AuthProvider>
          {" "}
          {/* Existing AuthProvider remains here */}
          <div className="App">
            <Header />
            <div className="App-content">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/perguntas" element={<Perguntas />} />
                <Route path="/contato" element={<Contato />} />
                <Route
                  path="/dermato-esteticos"
                  element={<DermatoEsteticos />}
                />
                <Route path="/ganhe-sessoes" element={<GanheSessoes />} />
                <Route path="/depilacao" element={<Depilacao />} />
                <Route path="/pelometro" element={<Pelometro />} />
                <Route path="/login" element={<Login />} />
                <Route
                  path="/calculadoraconsultoras"
                  element={
                    <PrivateRoute>
                      <CalculadoraOrcamentoConsultoras />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/calculadoraaplicadoras"
                  element={
                    <PrivateRoute>
                      <CalculadoraOrcamentoTecnicas />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/anamnesedepilacao"
                  element={<AnamneseFormDepilacao />}
                />
                <Route path="/anamneseomer" element={<AnamneseFormOmer />} />
                <Route
                  path="/controledegestao"
                  element={
                    <AdminPrivateRoute>
                      <ControleGestao />
                    </AdminPrivateRoute>
                  }
                />
                <Route
                  path="/user-dashboard"
                  element={
                    <UserPrivateRoute>
                      <UserDashboard />
                    </UserPrivateRoute>
                  }
                />
                <Route
                  path="/gestaoleads"
                  element={
                    <RestrictedUserPrivateRoute>
                      <GestaoLeads />
                    </RestrictedUserPrivateRoute>
                  }
                />
                <Route path="/loginleads" element={<LoginLeads />} />
                <Route path="/loginFirebase" element={<LoginFirebase />} />
              </Routes>
            </div>
            <SubFooter />
            <Footer />
          </div>
        </AuthProvider>
      </Router>
    </FirebaseAuthProvider>
  );
}

export default App;
