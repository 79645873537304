// AdminPrivateRoute.js
import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useFirebaseAuth } from "./FirebaseAuthContext";
import { db } from "../config/firebase";
import { doc, getDoc } from "firebase/firestore";

const AdminPrivateRoute = ({ children }) => {
  const { currentUser } = useFirebaseAuth(); // Usa o novo contexto
  const [userRole, setUserRole] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkUserRole = async () => {
      if (currentUser) {
        try {
          const userDocRef = doc(db, "users", currentUser.uid);
          const userDoc = await getDoc(userDocRef);
          const userData = userDoc.data();
          setUserRole(userData?.role);
        } catch (error) {
          console.error("Error checking user role:", error);
        }
      }
      setLoading(false);
    };

    checkUserRole();
  }, [currentUser]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (currentUser && userRole === "admin") {
    return children; // Render admin content
  } else if (currentUser && userRole === "user") {
    return <Navigate to="/user-dashboard" />; // Redirect regular users to their dashboard
  } else {
    return <Navigate to="/loginfirebase" />; // Redirect to login if not logged in
  }
};

export default AdminPrivateRoute;
