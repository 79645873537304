import React, { useState, useEffect } from "react";
import { db } from "../config/firebase"; // Certifique-se de que o caminho está correto
import { collection, addDoc, onSnapshot } from "firebase/firestore";
import Select from "react-select";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import "./GestaoLeads.css";

const GestaoLeads = () => {
  const [leads, setLeads] = useState([]);
  const [newLead, setNewLead] = useState({
    nome: "",
    telefone: "",
    etiqueta: [],
    dataInteracao: "",
    consultora: "",
  });
  const [isAdding, setIsAdding] = useState(false); // Estado para controlar a exibição do modal
  const [isChartVisible, setIsChartVisible] = useState(false); // Estado para controlar a visibilidade do gráfico

  const [leadsPerDay, setLeadsPerDay] = useState({});
  const [startDate, setStartDate] = useState("2024-10-01"); // Data de início padrão
  const [endDate, setEndDate] = useState("2024-10-31"); // Data de término padrão

  // Função para converter data para o formato ISO (YYYY-MM-DD)
  const formatDateToISO = (date) => {
    const [day, month, year] = date.split("/");
    return `${year}-${month}-${day}`;
  };

  // Função para formatar e contar leads por dia no intervalo especificado
  const countLeadsByDate = (leadsData) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const leadsInRange = leadsData.filter((lead) => {
      const leadDate = new Date(formatDateToISO(lead.dataInteracao));
      return leadDate >= start && leadDate <= end;
    });

    const leadsCount = {};
    for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
      const dateStr = d.toISOString().split("T")[0];
      leadsCount[dateStr] = leadsInRange.filter(
        (lead) => formatDateToISO(lead.dataInteracao) === dateStr
      ).length;
    }
    setLeadsPerDay(leadsCount);
  };

  // Calcula a média de leads por dia no intervalo
  const totalLeads = Object.values(leadsPerDay).reduce(
    (sum, count) => sum + count,
    0
  );
  const daysInRange = Object.keys(leadsPerDay).length;
  const averageLeads = daysInRange > 0 ? totalLeads / daysInRange : 0;

  // Prepara os dados para as linhas de média e mínimo esperado
  const averageLine = Array(daysInRange).fill(averageLeads);
  const minimumLine = Array(daysInRange).fill(8); // Linha de mínimo esperado de 8

  const etiquetaOptions = [
    { value: "Lead Whatsapp", label: "Lead Whatsapp" },
    { value: "Lead Instagram", label: "Lead Instagram" },
    { value: "Depilação-Laser", label: "Depilação-Laser" },
    { value: "Remoc.Tattoo", label: "Remoc.Tattoo" },
    { value: "Clareamento", label: "Clareamento" },
  ];

  const consultoraOptions = [
    { value: "Ana", label: "Ana" },
    { value: "Carolina", label: "Carolina" },
  ];

  // Função para formatar o número de telefone
  const formatPhoneNumber = (phone) => {
    if (!phone) return ""; // Retorna vazio se o telefone não estiver presente

    // Remove todos os caracteres não numéricos
    const cleaned = phone.replace(/\D/g, "");

    // Formata para o padrão (XX) XXXXX-XXXX
    const match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/);
    return match ? `(${match[1]}) ${match[2]}-${match[3]}` : phone;
  };

  // Função para adicionar um novo lead
  const addLead = async (e) => {
    e.preventDefault();

    // Formata o telefone antes de salvar
    const formattedPhone = formatPhoneNumber(newLead.telefone);

    // Adiciona o lead no Firestore
    await addDoc(collection(db, "leads"), {
      ...newLead,
      telefone: formattedPhone,
      etiqueta: newLead.etiqueta.map((option) => option.value),
    });

    // Limpa o formulário e fecha o modal
    setNewLead({
      nome: "",
      telefone: "",
      etiqueta: [],
      dataInteracao: "",
      consultora: "",
    });
    setIsAdding(false);
  };

  // Função para buscar leads em tempo real
  useEffect(() => {
    const leadsRef = collection(db, "leads");
    const unsubscribe = onSnapshot(leadsRef, (snapshot) => {
      const leadsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setLeads(leadsData);
      countLeadsByDate(leadsData);
    });

    return () => unsubscribe();
  }, [startDate, endDate]); // Recalcula o gráfico sempre que as datas mudarem

  const anaCount = leads.filter((leads) => leads.consultora === "Ana").length;
  const carolinaCount = leads.filter(
    (leads) => leads.consultora === "Carolina"
  ).length;

  const data = {
    labels: Object.keys(leadsPerDay),
    datasets: [
      {
        label: "Número de Leads por Dia",
        data: Object.values(leadsPerDay),
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        fill: true,
      },
      {
        label: "Média de Leads por Dia",
        data: averageLine,
        borderColor: "rgba(54, 162, 235, 1)",
        borderDash: [5, 5],
        fill: false,
      },
      {
        label: "Mínimo Esperado (8)",
        data: minimumLine,
        borderColor: "rgba(255, 99, 132, 1)",
        borderDash: [5, 5],
        fill: false,
      },
    ],
  };

  return (
    <div className="gestao-leads-container">
      <h2>Gestão dos Leads</h2>
      <button className="cadastrar-lead-btn" onClick={() => setIsAdding(true)}>
        Cadastrar Lead
      </button>
      <div className="contador-anotacoes">
        <div className="contador-box">
          <p className="contador-titulo">Leads Ana</p>
          <p className="contador-numero">{anaCount}</p>
        </div>
        <div className="contador-box">
          <p className="contador-titulo">Leads Carolina</p>
          <p className="contador-numero">{carolinaCount}</p>
        </div>
      </div>
      {/* Botão para mostrar/ocultar gráfico */}
      <button onClick={() => setIsChartVisible(!isChartVisible)}>
        {isChartVisible ? "Fechar Gráfico" : "Mostrar Gráfico"}
      </button>

      {/* Filtros de data */}
      {isChartVisible && (
        <div>
          <div className="date-filters">
            <label>Data de Início:</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <label>Data de Fim:</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>

          {/* Renderizando o gráfico */}
          <div className="lead-chart">
            <h3>
              Leads de {startDate} a {endDate}
            </h3>
            <Line data={data} />
          </div>
        </div>
      )}

      {isAdding && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Cadastrar Lead</h3>
            <form onSubmit={addLead}>
              <label>Nome:</label>
              <input
                type="text"
                placeholder="Nome"
                value={newLead.nome}
                onChange={(e) =>
                  setNewLead({ ...newLead, nome: e.target.value })
                }
                required
              />

              <label>Telefone:</label>
              <input
                type="text"
                placeholder="(XX) XXXXX-XXXX"
                value={newLead.telefone}
                onChange={(e) =>
                  setNewLead({ ...newLead, telefone: e.target.value })
                }
                required
              />

              <label>Etiqueta:</label>
              <Select
                isMulti
                options={etiquetaOptions}
                value={newLead.etiqueta}
                onChange={(selectedOptions) =>
                  setNewLead({ ...newLead, etiqueta: selectedOptions })
                }
              />

              <label>Data de Interação:</label>
              <input
                type="date"
                value={newLead.dataInteracao}
                onChange={(e) =>
                  setNewLead({ ...newLead, dataInteracao: e.target.value })
                }
                required
              />
              <label>Consultora:</label>
              <Select
                options={consultoraOptions}
                value={consultoraOptions.find(
                  (option) => option.value === newLead.consultora
                )}
                onChange={(selectedOption) =>
                  setNewLead({ ...newLead, consultora: selectedOption.value })
                }
                placeholder="Selecione a consultora"
                required
              />

              <button type="submit">Salvar Lead</button>
              <button type="button" onClick={() => setIsAdding(false)}>
                Cancelar
              </button>
            </form>
          </div>
        </div>
      )}

      <h2>Lista de Leads Cadastrados</h2>
      <table>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Telefone</th>
            <th>Etiqueta</th>
            <th>Data de Interação</th>
            <th>Consultora</th>
          </tr>
        </thead>
        <tbody>
          {leads.map((lead) => (
            <tr key={lead.id}>
              <td>{lead.nome}</td>
              <td>{formatPhoneNumber(lead.telefone)}</td>{" "}
              {/* Exibe o telefone formatado */}
              <td>
                {Array.isArray(lead.etiqueta)
                  ? lead.etiqueta.join(", ")
                  : lead.etiqueta}
              </td>{" "}
              {/* Garantir que seja um array */}
              <td>{lead.dataInteracao}</td>
              <td>{lead.consultora}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default GestaoLeads;
