// AuthContext.js
import React, { createContext, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  const login = (username, password) => {
    // Exemplo simples de autenticação
    if (
      (username === "admin-con" && password === "ger.123@") ||
      (username === "ana" && password === "con1.123@") ||
      (username === "carolina" && password === "con2.123@")
    ) {
      setUser({ username });
      navigate("/calculadoraconsultoras"); // Redireciona para a calculadora após login
      return true;
    } else if (
      (username === "admin-tec" && password === "ger.123@") ||
      (username === "enny" && password === "tec1.123@") ||
      (username === "isabella" && password === "tec2.123@")
    ) {
      setUser({ username });
      navigate("/calculadoraaplicadoras"); // Redireciona para a calculadora após login
      return true;
    }
    return false;
  };

  const logout = () => {
    setUser(null);
    navigate("/login");
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
