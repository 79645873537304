import React, { useState } from "react";
import "./main.css";
import emailjs from "emailjs-com";

const AnamneseFormOmer = () => {
  // Estado para controlar se a imagem está expandida ou Não
  const [isExpanded, setIsExpanded] = useState(false);

  // Função para alternar entre a versão expandida e reduzida da imagem
  const toggleImageSize = () => {
    setIsExpanded(!isExpanded);
  };

  const [formData, setFormData] = useState({
    nome: "",
    sexo: "FEMININO",
    tratamentoAnterior: "",
    descricaoTratamentoAnterior: "",
    lenteContato: "",
    usoCosmetico: "",
    descricaoCosmetico: "",
    exposicaoSol: "",
    filtroSolar: "",
    frequenciaFiltro: "",
    fuma: "",
    frequenciaFuma: "",
    bebeAlcool: "",
    frequenciaBebeAlcool: "",
    bebeAgua: "",
    alimentacao: "",
    anticoncepcional: "",
    gestanteLactante: "",
    descricaoAnticoncepcional: "",
    tratamentoMedico: "",
    descricaoTratamentoMedico: "",
    usaMedicamentos: "",
    intoleranciaCalor: "",
    melasma: "",
    alergia: "",
    descricaoAlergia: "",
    marcapasso: "",
    hiperhipoTensao: "",
    disturbioHormonal: "",
    descricaoDisturbioHormonal: "",
    disturbioGastro: "",
    descricaoDisturbioGastro: "",
    epilepsia: "",
    frequenciaEpilepsia: "",
    oncológico: "",
    descricaoOncologico: "",
    diabetes: "",
    descricaoDiabetes: "",
    outraDoenca: "",
    descricaoaOutraDoenca: "",
    protese: "",
    tipoProtese: "",
    regiaoProtese: "",
    tratamentoDermatologico: "",
    descricaoTratamentoDermatologico: "",
    cirurgiaPlastica: "",
    descricaoCirurgiaPlastica: "",
    liftingFacial: "",
    descricaoLiftingFacial: "",
    fototipo: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Mapeamento das perguntas completas com suas chaves
  const perguntas = {
    nome: "Qual é o seu nome?",
    tratamentoAnterior:
      "1. Já realizou algum tratamento estético anteriormente? ",
    descricaoTratamentoAnterior: "Se sim, qual? ",
    lenteContato: "2. Usa lentes de contato?  ",
    usoCosmetico: "3. Usa cosméticos?  ",
    descricaoCosmetico: "Se sim, qual? ",
    exposicaoSol: "4. Tem o hábito de se expor ao sol?  ",
    filtroSolar: "5. Usa filtro solar?  ",
    frequenciaFiltro: "Frequência:  ",
    fuma: "6. Fuma (tabagismo)?  ",
    frequenciaFuma: "Quantidade de cigarros/dia:  ",
    bebeAlcool: "7. Ingere bebida alcoólica?  ",
    frequenciaBebeAlcool: "Frequência:  ",
    bebeAgua: "8. Qual a sua rotina de ingestão de água (copos/dia)?  ",
    alimentacao: "9. Como considera sua alimentação?  ",
    tratamentoMedico: "10. Está sob tratamento médico?  ",
    descricaoTratamentoMedico: "Se sim, qual?   ",
    usaMedicamentos: "11. Faz uso de medicamentos?  ",
    intoleranciaCalor: "12. Tem intolerância ao calor?  ",
    melasma: "13. Possui melasma?  ",
    alergia: "14. Tem antecedentes alérgicos?  ",
    descricaoAlergia: "Se sim, qual?",
    marcapasso: "15. Usa marcapasso ou stent?  ",
    hiperhipoTensao: "16. Possui hipotensão ou hipertensão arterial?  ",
    disturbioHormonal: "17. Apresenta distúrbio hormonal?  ",
    descricaoDisturbioHormonal: "Se sim, qual?  ",
    disturbioGastro: "18. Possui distúrbio gastrointestinal?  ",
    descricaoDisturbioGastro: "Se sim, qual?  ",
    epilepsia: "19. Tem histórico de epilepsia e/ou convulsões?  ",
    frequenciaEpilepsia: "Frequência:  ",
    oncológico: "20. Tem antecedentes oncológicos?  ",
    descricaoOncologico: "Se sim, qual?  ",
    diabetes: "21. Possui diabetes?  ",
    descricaoDiabetes: "Se sim, qual tipo?  ",
    outraDoenca: "22. Possui alguma outra doença? ",
    descricaoaOutraDoenca: "Se sim, qual? ",
    protese: "23. Tem próteses ou pinos metálicos na área de aplicação?    ",
    tipoProtese: "Tipo:   ",
    regiaoProtese: "Regiões:  ",
    tratamentoDermatologico: "24. Está em tratamento dermatológico/estético?",
    descricaoTratamentoDermatologico: "Se sim, qual?  ",
    cirurgiaPlastica: "25. Já realizou cirurgia plástica estética?  ",
    descricaoCirurgiaPlastica: "Se sim, qual?  ",
    liftingFacial: "26. Já realizou lifting facial?  ",
    descricaoLiftingFacial: "Se sim, qual?  ",
    anticoncepcional: "27. Usa anticoncepcional?  ",
    descricaoAnticoncepcional: "Se sim, qual? ",
    gestanteLactante: "28. Está gestante ou lactante?",
    fototipo: "Qual é o seu fototipo?",
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Verificar se todos os campos obrigatórios foram preenchidos

    let camposObrigatorios = [
      "nome",
      "sexo",
      "tratamentoAnterior",
      "lenteContato",
      "usoCosmetico",
      "exposicaoSol",
      "filtroSolar",
      "fuma",
      "bebeAlcool",
      "bebeAgua",
      "alimentacao",
      "tratamentoMedico",
      "usaMedicamentos",
      "intoleranciaCalor",
      "melasma",
      "alergia",
      "marcapasso",
      "hiperhipoTensao",
      "disturbioHormonal",
      "disturbioGastro",
      "epilepsia",
      "oncológico",
      "diabetes",
      "outraDoenca",
      "protese",
      "tratamentoDermatologico",
      "cirurgiaPlastica",
      "liftingFacial",
    ];

    // Adiciona campos específicos se o sexo for "FEMININO"
    if (formData.sexo === "FEMININO") {
      camposObrigatorios = camposObrigatorios.concat([
        "anticoncepcional",
        "gestanteLactante",
      ]);
    }

    // Verifica se algum dos campos obrigatórios está vazio
    const camposVazios = camposObrigatorios.some(
      (campo) => formData[campo] === ""
    );

    // Encontre os campos que estão vazios
    if (camposVazios) {
      alert(
        "Por favor, preencha todos os campos obrigatórios antes de enviar."
      );
      console.log(
        "Campos vazios:",
        camposObrigatorios.filter((campo) => !formData[campo])
      );
      return; // Interrompe o envio se houver campos vazios
    }

    // Processar e enviar a mensagem para o WhatsApp
    const filteredData = Object.entries(formData)
      .filter(
        ([key, value]) =>
          value === "Sim" ||
          (key.includes("descricao") && value) ||
          key === "nome" ||
          key === "usaMedicamentos" ||
          key === "fototipo"
      )
      .map(([key, value]) => {
        const formattedKey = perguntas[key] || key;
        return `${formattedKey} ${value}`;
      })
      .join("\n\n"); // Adiciona uma linha em branco entre cada resposta para facilitar a leitura

    const hasSimResponse = Object.values(formData).includes("Sim");

    // Defina a mensagem de WhatsApp com base na presença de respostas "Sim"
    const whatsappMessage = hasSimResponse
      ? `*Olá, já preenchi meu formulário e peço atenção às perguntas que foram respondidas com "Sim".*:\n\n${filteredData}`
      : `*Olá, já preenchi meu formulário e todas as perguntas foram respondidas com "Não".*:\n\n${filteredData}`;
    const whatsappUrl = `https://wa.me/5521991261684?text=${encodeURIComponent(
      whatsappMessage
    )}`;

    window.open(whatsappUrl, "_blank");
    emailjs
      .send(
        "service_zghc0r8",
        "template_6pdxken",
        {
          message: filteredData, // Esta linha é onde o conteúdo do formulário será enviado
          ...formData, // opcional: envia todos os dados do formulário individualmente
        },
        "ec-5pkxsI1GLXhY-r"
      )
      .then(
        (result) => {
          console.log("Email successfully sent:", result.text);
        },
        (error) => {
          console.log("Failed to send email:", error.text);
        }
      );
  };

  return (
    <div className="anamnese">
      <div className="anamnese-form" style={{ padding: "20px" }}>
        <h2>Formulário de Anamnese - Ômer</h2>
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <div>
            <label>Preencha nome completo</label>
            <input
              type="text"
              name="nome"
              value={formData.nome}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Sexo</label>
            <div className="option-group">
              <input
                type="radio"
                name="sexo"
                value="MASCULINO"
                onChange={handleChange}
              />{" "}
              Masculino
              <input
                type="radio"
                name="sexo"
                value="FEMININO"
                onChange={handleChange}
              />{" "}
              Feminino
            </div>
          </div>
          <div>
            <label>
              1. Já realizou algum tratamento estético anteriormente?
            </label>
            <div className="option-group">
              <input
                type="radio"
                name="tratamentoAnterior"
                value="Sim"
                onChange={handleChange}
              />{" "}
              Sim
              <input
                type="radio"
                name="tratamentoAnterior"
                value="Não"
                onChange={handleChange}
              />{" "}
              Não
            </div>
            {formData.tratamentoAnterior === "Sim" && (
              <div>
                <label>Se sim, qual?</label>
                <input
                  type="text"
                  name="descricaoTratamentoAnterior"
                  value={formData.descricaoTratamentoAnterior}
                  onChange={handleChange}
                  required
                />
              </div>
            )}
          </div>
          <div>
            <label>2. Usa lentes de contato?</label>
            <div className="option-group">
              <input
                type="radio"
                name="lenteContato"
                value="Sim"
                onChange={handleChange}
              />{" "}
              Sim
              <input
                type="radio"
                name="lenteContato"
                value="Não"
                onChange={handleChange}
              />{" "}
              Não
            </div>
          </div>
          <div>
            <label>3. Usa cosméticos?</label>
            <div className="option-group">
              <input
                type="radio"
                name="usoCosmetico"
                value="Sim"
                onChange={handleChange}
              />{" "}
              Sim
              <input
                type="radio"
                name="usoCosmetico"
                value="Não"
                onChange={handleChange}
              />{" "}
              Não
            </div>
            {formData.usoCosmetico === "Sim" && (
              <div>
                <label>Se sim, qual?</label>
                <input
                  type="text"
                  name="descricaoCosmetico"
                  value={formData.descricaoCosmetico}
                  onChange={handleChange}
                  required
                />
              </div>
            )}
          </div>
          <div>
            <label>4. Tem o hábito de se expor ao sol?</label>
            <div className="option-group">
              <input
                type="radio"
                name="exposicaoSol"
                value="Sim"
                onChange={handleChange}
              />{" "}
              Sim
              <input
                type="radio"
                name="exposicaoSol"
                value="Não"
                onChange={handleChange}
              />{" "}
              Não
            </div>
          </div>
          <div>
            <label>5. Usa filtro solar?</label>
            <div className="option-group">
              <input
                type="radio"
                name="filtroSolar"
                value="Sim"
                onChange={handleChange}
              />{" "}
              Sim
              <input
                type="radio"
                name="filtroSolar"
                value="Não"
                onChange={handleChange}
              />{" "}
              Não
            </div>
            {formData.filtroSolar === "Sim" && (
              <div>
                <label>Frequência:</label>
                <input
                  type="text"
                  name="frequenciaFiltro"
                  value={formData.frequenciaFiltro}
                  onChange={handleChange}
                  required
                />
              </div>
            )}
          </div>

          <div>
            <label>6. Fuma (tabagismo)?</label>
            <div className="option-group">
              <input
                type="radio"
                name="fuma"
                value="Sim"
                onChange={handleChange}
              />{" "}
              Sim
              <input
                type="radio"
                name="fuma"
                value="Não"
                onChange={handleChange}
              />{" "}
              Não
            </div>
            {formData.fuma === "Sim" && (
              <div>
                <label>Quantidade de cigarros/dia: </label>
                <input
                  type="text"
                  name="frequenciaFuma"
                  value={formData.frequenciaFuma}
                  onChange={handleChange}
                  required
                />
              </div>
            )}
          </div>

          <div>
            <label>7. Ingere bebida alcoólica?</label>
            <div className="option-group">
              <input
                type="radio"
                name="bebeAlcool"
                value="Sim"
                onChange={handleChange}
              />{" "}
              Sim
              <input
                type="radio"
                name="bebeAlcool"
                value="Não"
                onChange={handleChange}
              />{" "}
              Não
            </div>
            {formData.bebeAlcool === "Sim" && (
              <div>
                <label>Frequência:</label>
                <input
                  type="text"
                  name="frequenciaBebeAlcool"
                  value={formData.frequenciaBebeAlcool}
                  onChange={handleChange}
                  required
                />
              </div>
            )}
          </div>

          <div>
            <label>8. Qual a sua rotina de ingestão de água (copos/dia)?</label>
            <div className="option-group">
              <input
                type="text"
                name="bebeAgua"
                value={formData.bebeAgua}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div>
            <label>9. Como considera sua alimentação?</label>
            <div className="option-group">
              <input
                type="radio"
                name="alimentacao"
                value="Boa"
                onChange={handleChange}
              />{" "}
              Boa
              <input
                type="radio"
                name="alimentacao"
                value="Regular"
                onChange={handleChange}
              />{" "}
              Regular
              <input
                type="radio"
                name="alimentacao"
                value="Péssima"
                onChange={handleChange}
              />{" "}
              Péssima
            </div>
          </div>
          <div>
            <label>10. Está sob tratamento médico?</label>
            <div className="option-group">
              <input
                type="radio"
                name="tratamentoMedico"
                value="Sim"
                onChange={handleChange}
              />{" "}
              Sim
              <input
                type="radio"
                name="tratamentoMedico"
                value="Não"
                onChange={handleChange}
              />{" "}
              Não
            </div>
            {formData.tratamentoMedico === "Sim" && (
              <div>
                <label>Se sim, qual?</label>
                <input
                  type="text"
                  name="descricaoTratamentoMedico"
                  value={formData.descricaoTratamentoMedico}
                  onChange={handleChange}
                  required
                />
              </div>
            )}
          </div>

          <div>
            <label>11. Faz uso de medicamentos? Quais?</label>
            <div className="option-group">
              <input
                type="text"
                name="usaMedicamentos"
                value={formData.usaMedicamentos}
                onChange={handleChange}
                required
              />{" "}
            </div>
          </div>

          <div>
            <label>12. Tem intolerância ao calor?</label>
            <div className="option-group">
              <input
                type="radio"
                name="intoleranciaCalor"
                value="Sim"
                onChange={handleChange}
              />{" "}
              Sim
              <input
                type="radio"
                name="intoleranciaCalor"
                value="Não"
                onChange={handleChange}
              />{" "}
              Não
            </div>
          </div>
          <div>
            <label>13. Possui melasma?</label>
            <div className="option-group">
              <input
                type="radio"
                name="melasma"
                value="Sim"
                onChange={handleChange}
              />{" "}
              Sim
              <input
                type="radio"
                name="melasma"
                value="Não"
                onChange={handleChange}
              />{" "}
              Não
            </div>
          </div>
          <div>
            <label>14. Tem antecedentes alérgicos?</label>
            <div className="option-group">
              <input
                type="radio"
                name="alergia"
                value="Sim"
                onChange={handleChange}
              />{" "}
              Sim
              <input
                type="radio"
                name="alergia"
                value="Não"
                onChange={handleChange}
              />{" "}
              Não
            </div>
            {formData.tratamentoMedico === "Sim" && (
              <div>
                <label>Se sim, qual?</label>
                <input
                  type="text"
                  name="descricaoAlergia"
                  value={formData.descricaoAlergia}
                  onChange={handleChange}
                  required
                />
              </div>
            )}
          </div>
          <div>
            <label>15. Usa marcapasso ou stent? </label>
            <div className="option-group">
              <input
                type="radio"
                name="marcapasso"
                value="Sim"
                onChange={handleChange}
              />{" "}
              Sim
              <input
                type="radio"
                name="marcapasso"
                value="Não"
                onChange={handleChange}
              />{" "}
              Não
            </div>
          </div>
          <div>
            <label>16. Possui hipotensão ou hipertensão arterial?</label>
            <div className="option-group">
              <input
                type="radio"
                name="hiperhipoTensao"
                value="Sim"
                onChange={handleChange}
              />{" "}
              Sim
              <input
                type="radio"
                name="hiperhipoTensao"
                value="Não"
                onChange={handleChange}
              />{" "}
              Não
            </div>
          </div>
          <div>
            <label>17. Apresenta distúrbio hormonal?</label>
            <div className="option-group">
              <input
                type="radio"
                name="disturbioHormonal"
                value="Sim"
                onChange={handleChange}
              />{" "}
              Sim
              <input
                type="radio"
                name="disturbioHormonal"
                value="Não"
                onChange={handleChange}
              />{" "}
              Não
            </div>
            {formData.disturbioHormonal === "Sim" && (
              <div>
                <label>Se sim, qual?</label>
                <input
                  type="text"
                  name="descricaoDisturbioHormonal"
                  value={formData.descricaoDisturbioHormonal}
                  onChange={handleChange}
                  required
                />
              </div>
            )}
          </div>
          <div>
            <label>18. Possui distúrbio gastrointestinal?</label>
            <div className="option-group">
              <input
                type="radio"
                name="disturbioGastro"
                value="Sim"
                onChange={handleChange}
              />{" "}
              Sim
              <input
                type="radio"
                name="disturbioGastro"
                value="Não"
                onChange={handleChange}
              />{" "}
              Não
            </div>
            {formData.disturbioGastro === "Sim" && (
              <div>
                <label>Se sim, qual?</label>
                <input
                  type="text"
                  name="descricaoDisturbioGastro"
                  value={formData.descricaoDisturbioGastro}
                  onChange={handleChange}
                  required
                />
              </div>
            )}
          </div>
          <div>
            <label>19. Tem histórico de epilepsia e/ou convulsões?</label>
            <div className="option-group">
              <input
                type="radio"
                name="epilepsia"
                value="Sim"
                onChange={handleChange}
              />{" "}
              Sim
              <input
                type="radio"
                name="epilepsia"
                value="Não"
                onChange={handleChange}
              />{" "}
              Não
            </div>
            {formData.epilepsia === "Sim" && (
              <div>
                <label>Frequência:</label>
                <input
                  type="text"
                  name="frequenciaEpilepsia"
                  value={formData.frequenciaEpilepsia}
                  onChange={handleChange}
                  required
                />
              </div>
            )}
          </div>
          <div>
            <label>20. Tem antecedentes oncológicos? </label>
            <div className="option-group">
              <input
                type="radio"
                name="oncológico"
                value="Sim"
                onChange={handleChange}
              />{" "}
              Sim
              <input
                type="radio"
                name="oncológico"
                value="Não"
                onChange={handleChange}
              />{" "}
              Não
            </div>
            {formData.oncológico === "Sim" && (
              <div>
                <label>Se sim, qual?</label>
                <input
                  type="text"
                  name="descricaoOncologico"
                  value={formData.descricaoOncologico}
                  onChange={handleChange}
                  required
                />
              </div>
            )}
          </div>
          <div>
            <label>21. Possui diabetes?</label>
            <div className="option-group">
              <input
                type="radio"
                name="diabetes"
                value="Sim"
                onChange={handleChange}
              />{" "}
              Sim
              <input
                type="radio"
                name="diabetes"
                value="Não"
                onChange={handleChange}
              />{" "}
              Não
            </div>
            {formData.diabetes === "Sim" && (
              <div>
                <label>Se sim, qual tipo?</label>
                <input
                  type="text"
                  name="descricaoDiabetes"
                  value={formData.descricaoDiabetes}
                  onChange={handleChange}
                  required
                />
              </div>
            )}
          </div>
          <div>
            <label>22. Possui alguma outra doença?</label>
            <div className="option-group">
              <input
                type="radio"
                name="outraDoenca"
                value="Sim"
                onChange={handleChange}
              />{" "}
              Sim
              <input
                type="radio"
                name="outraDoenca"
                value="Não"
                onChange={handleChange}
              />{" "}
              Não
            </div>
            {formData.outraDoenca === "Sim" && (
              <div>
                <label>Se sim, qual?</label>
                <input
                  type="text"
                  name="descricaoaOutraDoenca"
                  value={formData.descricaoaOutraDoenca}
                  onChange={handleChange}
                  required
                />
              </div>
            )}
          </div>
          <div>
            <label>
              23. Tem próteses ou pinos metálicos na área de aplicação?
            </label>
            <div className="option-group">
              <input
                type="radio"
                name="protese"
                value="Sim"
                onChange={handleChange}
              />{" "}
              Sim
              <input
                type="radio"
                name="protese"
                value="Não"
                onChange={handleChange}
              />{" "}
              Não
            </div>
            {formData.protese === "Sim" && (
              <div>
                <label>Tipo: </label>
                <input
                  type="text"
                  name="tipoProtese"
                  value={formData.tipoProtese}
                  onChange={handleChange}
                  required
                />
                <label>Regiões: </label>
                <input
                  type="text"
                  name="regiaoProtese"
                  value={formData.regiaoProtese}
                  onChange={handleChange}
                  required
                />
              </div>
            )}
          </div>
          <div>
            <label>24. Está em tratamento dermatológico/estético?</label>
            <div className="option-group">
              <input
                type="radio"
                name="tratamentoDermatologico"
                value="Sim"
                onChange={handleChange}
              />{" "}
              Sim
              <input
                type="radio"
                name="tratamentoDermatologico"
                value="Não"
                onChange={handleChange}
              />{" "}
              Não
            </div>
            {formData.tratamentoDermatologico === "Sim" && (
              <div>
                <label>Se sim, qual?</label>
                <input
                  type="text"
                  name="descricaoTratamentoDermatologico"
                  value={formData.descricaoTratamentoDermatologico}
                  onChange={handleChange}
                  required
                />
              </div>
            )}
          </div>
          <div>
            <label>25. Já realizou cirurgia plástica estética?</label>
            <div className="option-group">
              <input
                type="radio"
                name="cirurgiaPlastica"
                value="Sim"
                onChange={handleChange}
              />{" "}
              Sim
              <input
                type="radio"
                name="cirurgiaPlastica"
                value="Não"
                onChange={handleChange}
              />{" "}
              Não
            </div>
            {formData.cirurgiaPlastica === "Sim" && (
              <div>
                <label>Se sim, qual?</label>
                <input
                  type="text"
                  name="descricaoCirurgiaPlastica"
                  value={formData.descricaoCirurgiaPlastica}
                  onChange={handleChange}
                  required
                />
              </div>
            )}
          </div>
          <div>
            <label>26. Já realizou lifting facial?</label>
            <div className="option-group">
              <input
                type="radio"
                name="liftingFacial"
                value="Sim"
                onChange={handleChange}
              />{" "}
              Sim
              <input
                type="radio"
                name="liftingFacial"
                value="Não"
                onChange={handleChange}
              />{" "}
              Não
            </div>
            {formData.liftingFacial === "Sim" && (
              <div>
                <label>Se sim, qual?</label>
                <input
                  type="text"
                  name="descricaoLiftingFacial"
                  value={formData.descricaoLiftingFacial}
                  onChange={handleChange}
                  required
                />
              </div>
            )}
          </div>
          {formData.sexo === "FEMININO" && (
            <div>
              <label>27. Usa anticoncepcional?</label>
              <div className="option-group">
                <input
                  type="radio"
                  name="anticoncepcional"
                  value="Sim"
                  onChange={handleChange}
                />{" "}
                Sim
                <input
                  type="radio"
                  name="anticoncepcional"
                  value="Não"
                  onChange={handleChange}
                />{" "}
                Não
              </div>
              {formData.anticoncepcional === "Sim" && (
                <div>
                  <label>Se sim, qual?</label>
                  <input
                    type="text"
                    name="descricaoAnticoncepcional"
                    value={formData.descricaoAnticoncepcional}
                    onChange={handleChange}
                    required
                  />
                </div>
              )}
            </div>
          )}

          {formData.sexo === "FEMININO" && (
            <div>
              <label>28. Está gestante ou lactante?</label>
              <div className="option-group">
                <input
                  type="radio"
                  name="gestanteLactante"
                  value="Sim"
                  onChange={handleChange}
                />{" "}
                Sim
                <input
                  type="radio"
                  name="gestanteLactante"
                  value="Não"
                  onChange={handleChange}
                />{" "}
                Não
              </div>
            </div>
          )}

          {formData.sexo === "FEMININO" && (
            <div>
              <label>29. Como você definiria seu fototipo?</label>
              <p>
                Clique na imagem para ver os tipos e selecione o seu. Fique
                tranquila, na Maislaser, nossas esteticistas realizarão uma
                avaliação cuidadosa para confirmar o grupo de fototipo ao qual
                você pertence.{" "}
              </p>
            </div>
          )}
          {formData.sexo === "MASCULINO" && (
            <div>
              <label>27. Como você definiria seu fototipo?</label>
              <p>
                Clique na imagem para ver os tipos e selecione o seu. Fique
                tranquilo, na Maislaser, nossas esteticistas realizarão uma
                avaliação cuidadosa para confirmar o grupo de fototipo ao qual
                você pertence.{" "}
              </p>
            </div>
          )}
          <div style={{ textAlign: "center" }}>
            {/* Imagem que expande e recolhe ao clicar */}
            <img
              src="/images/fototipo.jpg" // Substitua pelo caminho real da imagem
              alt="Fototipo"
              onClick={toggleImageSize}
              style={{
                borderRadius: "5px",
                width: isExpanded ? "300px" : "100px",
                cursor: "pointer",
                transition: "width 0.3s",
              }}
            />

            <div className="option-group" style={{ marginTop: "10px" }}>
              <p>Selecione seu fototipo:</p>
              <ul
                style={{
                  listStyleType: "none",
                  padding: 0,
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <div style={{ display: "flex", gap: "15px" }}>
                  <li>
                    <input
                      type="radio"
                      name="fototipo"
                      value="I"
                      onChange={handleChange}
                      required
                    />{" "}
                    Fototipo I
                  </li>
                  <li>
                    <input
                      type="radio"
                      name="fototipo"
                      value="II"
                      onChange={handleChange}
                      required
                    />{" "}
                    Fototipo II
                  </li>
                  <li>
                    <input
                      type="radio"
                      name="fototipo"
                      value="III"
                      onChange={handleChange}
                      required
                    />{" "}
                    Fototipo III
                  </li>
                </div>
                <div style={{ display: "flex", gap: "15px" }}>
                  <li>
                    <input
                      type="radio"
                      name="fototipo"
                      value="IV"
                      onChange={handleChange}
                      required
                    />{" "}
                    Fototipo IV
                  </li>
                  <li>
                    <input
                      type="radio"
                      name="fototipo"
                      value="V"
                      onChange={handleChange}
                      required
                    />{" "}
                    Fototipo V
                  </li>
                  <li>
                    <input
                      type="radio"
                      name="fototipo"
                      value="VI"
                      onChange={handleChange}
                      required
                    />{" "}
                    Fototipo VI
                  </li>
                </div>
              </ul>
            </div>
          </div>

          <button type="submit">Enviar via WhatsApp</button>
        </form>
      </div>
    </div>
  );
};

export default AnamneseFormOmer;
