// config/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAlPeqPME_271EV-UkB02YgESH9YlZ9DcE",
  authDomain: "maislaser---consultoras.firebaseapp.com",
  projectId: "maislaser---consultoras",
  storageBucket: "maislaser---consultoras.firebasestorage.app",
  messagingSenderId: "98611219697",
  appId: "1:98611219697:web:064d126676864de8fb2d50",
  measurementId: "G-74YGCR1NYR",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };
